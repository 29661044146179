<template>
  <div class="home" id="home">
    <div style="position: absolute; left: 58%; top: 50%; z-index: 999;">
      <img style="width: 5vw" src="@/assets/images/index/img14.png" alt="" />
      <div
        style="
          position: absolute;
          font-weight: bold;
          font-size: 14px;
          top: 1.85vh;
          left: 1.5vw;
          font-family: Microsoft YaHei;
          color: #47F4FF;
        "
      >
        翰思智造
      </div>
    </div>
    <div style="position: absolute; left: 50%; top: 48%; z-index: 999;">
      <img style="width: 5vw" src="@/assets/images/index/img_k.png" alt="" />
      <div class="mid-num">289</div>
      <div class="mid-num">区域数量</div>
    </div>
    <div style="position: absolute; left: 62%; top: 36%; z-index: 999;">
      <img style="width: 4vw" src="@/assets/images/index/img_gx.png" alt="" />
      <div class="mid-area">高新区</div>
    </div>
    <div style="position: absolute; left: 60.5%; top: 55%; z-index: 999;">
      <img style="width: 4vw" src="@/assets/images/index/img_gx.png" alt="" />
      <div class="mid-area">开发区</div>
    </div>
    <div class="title">
      <img style="width: 26.7vw" src="@/assets/images/index/title.png" alt="" />
    </div>
    <div class="left">
      <div class="left-card">
        <div class="left-title">
          <span>企业诊断</span>
        </div>
        <div class="echartBar" id="zx1"></div>
      </div>
      <div class="left-card">
        <div class="left-title">
          <span>企业上云</span>
        </div>
        <div class="company-cloud">
          <div class="company-cloud-info">
            <div style="margin-left: 3vw">
              <span>总数</span>
              <span
                style="margin-left: 0.5vw; font-size: 1.25vw; color: #ffe04d"
                >126</span
              >
            </div>
            <div>
              <span>三星</span>
              <span style="margin-left: 0.5vw; font-size: 1vw; color: #15bedd"
                >93</span
              >
            </div>
            <div>
              <span>四星</span>
              <span style="margin-left: 0.5vw; font-size: 1vw; color: #89c96a"
                >33</span
              >
            </div>
          </div>
          <div class="echartBar" id="companyCloud"></div>
        </div>
      </div>
      <div class="left-card">
        <div class="left-title">
          <span>两化融合贯标</span>
        </div>
        <div class="echartBar" id="twoCloud"></div>
      </div>
      <div class="left-card">
        <div class="left-title">
          <span>服务器资源</span>
        </div>
        <div class="serve-resource">
          <div style="display: flex;flex-flow: column;width: 50%;text-align: center;align-items: center;">
            <div class="serve-resource-item">
              <img src="@/assets/images/index/img5.png" alt="" />
              <div>
                <div style="font-size: 0.48vw">云主机</div>
                <div style="font-size: 0.96vw; font-family: Oswald">99</div>
              </div>
            </div>
            <div class="serve-resource-item">
              <img src="@/assets/images/index/img6.png" alt="" />
              <div>
                <div style="font-size: 0.48vw">私有主机</div>
                <div style="font-size: 0.96vw; font-family: Oswald">200</div>
              </div>
            </div>
          </div>
          <div class="serve-resource-item2">
            <div>服务器在线率</div>
            <div>85.11%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mid-top">
        <div class="mid-title">
          <span>当前：江都区</span>
        </div>
        <div class="mid-legend">
          <div class="circle1"></div>
          <span>在线</span>
        </div>
        <div class="mid-legend">
          <div class="circle2"></div>
          <span>不在线</span>
        </div>
        <div class="mid-legend">
          <img src="@/assets/images/index/img_ne.png" alt="" />
        </div>
    </div>
    <div class="mid">
      <div class="map" id="map"></div>
    </div>
    <div class="right">
      <div class="right-card">
        <div class="right-title">
          <span>智改数转网联</span>
        </div>
        <div class="right-card1">
          <div class="right-card1-middle">
            <div>
              <div style="color: #59abff">37</div>
              <div>实施中</div>
            </div>
            <div>
              <div style="color: #a7ffb6">8</div>
              <div>已实施</div>
            </div>
          </div>
          <div
            class="right-card1-bottom"
            id="card1Chart"
            style="height: 10vh"
          ></div>
        </div>
      </div>
      <div class="right-card">
        <div class="right-title">
          <span>区域企业库存周转率 </span>
        </div>
        <div class="right-card2">
          <div class="right-card2-item">
            <div class="right-card2-item-title">库存周转率</div>
            <div class="right-card2-item-logo">
              <img src="@/assets/images/index/icon2.png" alt="" />
            </div>
            <div class="right-card2-item-num">
              <img src="@/assets/images/index/dizuo.png" alt="" />
              <div>2.78</div>
            </div>
          </div>
          <div class="right-card2-item">
            <div class="right-card2-item-title">企业订单供需(万)</div>
            <div class="right-card2-item-logo">
              <img src="@/assets/images/index/icon3.png" alt="" />
            </div>
            <div class="right-card2-item-num">
              <img src="@/assets/images/index/dizuo.png" alt="" />
              <div>7551</div>
            </div>
          </div>
          <div class="right-card2-item">
            <div class="right-card2-item-title">6个月以上闲置库存(万)</div>
            <div class="right-card2-item-logo">
              <img src="@/assets/images/index/icon4.png" alt="" />
            </div>
            <div class="right-card2-item-num">
              <img src="@/assets/images/index/dizuo.png" alt="" />
              <div>1756</div>
            </div>
          </div>
          <div class="right-card2-item">
            <div class="right-card2-item-title">库存供需(万)</div>
            <div class="right-card2-item-logo">
              <img src="@/assets/images/index/icon5.png" alt="" />
            </div>
            <div class="right-card2-item-num">
              <img src="@/assets/images/index/dizuo.png" alt="" />
              <div>10291</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-card">
        <div class="right-title">
          <span>MES/ERP用量</span>
        </div>
        <div class="echartBar" id="mesEcharts" style="height: 16vh"></div>
      </div>
      <div class="right-card">
        <div class="right-title">
          <span>工业设备接入</span>
        </div>
        <div class="right-card4">
          <div class="right-card4-top">
            <div>
              <div style="color: #00f9fd">1407</div>
              <div>接入总数</div>
            </div>
            <div>
              <div style="color: #00fd59">1308</div>
              <div>当前在线数</div>
            </div>
          </div>
          <div class="right-card4-bottom">
            <div>
              <img
                style="width: 100%"
                src="@/assets/images/index/img12.png"
                alt=""
              />
            </div>
            <div>
              <div class="right-card4-bottom-num">248</div>
              <div class="right-card4-bottom-name">冲压设备</div>
            </div>
            <div>
              <div class="right-card4-bottom-num">225</div>
              <div class="right-card4-bottom-name">激光设备</div>
            </div>
            <div>
              <div class="right-card4-bottom-num">222</div>
              <div class="right-card4-bottom-name">焊接机器人</div>
            </div>
            <div>
              <div class="right-card4-bottom-num">196</div>
              <div class="right-card4-bottom-name">加工中心</div>
            </div>
          </div>
          <div class="right-card4-bottom" style="margin-top: 1vh">
            <div>
              <img
                style="width: 100%"
                src="@/assets/images/index/img13.png"
                alt=""
              />
            </div>
            <div>
              <div class="right-card4-bottom-num">310</div>
              <div class="right-card4-bottom-name">
                <img src="@/assets/images/index/c1.png" alt="" />
              </div>
            </div>
            <div>
              <div class="right-card4-bottom-num">365</div>
              <div class="right-card4-bottom-name">
                <img src="@/assets/images/index/c2.png" alt="" />
              </div>
            </div>
            <div>
              <div class="right-card4-bottom-num">281</div>
              <div class="right-card4-bottom-name">
                <img src="@/assets/images/index/c3.png" alt="" />
              </div>
            </div>
            <div>
              <div class="right-card4-bottom-num">253</div>
              <div class="right-card4-bottom-name">
                <img src="@/assets/images/index/c4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-main">
      <div class="center-card">
        <div class="center-card-info">
          <img src="@/assets/images/index/img1.png" alt="" />
          <div class="center-card-info-text">
            <div>
              <span style="font-size: 1.8vw">377</span>
              <span style="font-size: 0.42vw"> 项</span>
            </div>
            <div class="center-card-info-text-sub">数字画像诊断</div>
          </div>
        </div>
        <div class="center-card-info">
          <img src="@/assets/images/index/img2.png" alt="" />
          <div class="center-card-info-text">
            <div>
              <span style="font-size: 1.8vw">1407</span>
              <span style="font-size: 0.42vw"> 项</span>
            </div>
            <div class="center-card-info-text-sub">设备接入</div>
          </div>
        </div>
        <div class="center-card-info">
          <img src="@/assets/images/index/img3.png" alt="" />
          <div class="center-card-info-text">
            <div>
              <span style="font-size: 1.8vw">20</span>
              <span style="font-size: 0.42vw"> 项</span>
            </div>
            <div class="center-card-info-text-sub">两化融合贯标</div>
          </div>
        </div>
        <div class="center-card-info">
          <img src="@/assets/images/index/img4.png" alt="" />
          <div class="center-card-info-text">
            <div>
              <span style="font-size: 1.8vw">87</span>
              <span style="font-size: 0.42vw"> 项</span>
            </div>
            <div class="center-card-info-text-sub">智改数转网联</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { charts } from "../js/echart";
export default {
  name: "Index",
  components: {},
  data() {
    return {};
  },
  methods: {
    async initZZ() {
      charts.createZXCircle("#zx1", "green");
    },

    /**获取上云环图 */
    async initCloudLineChart() {
      var echartData = [
        {
          value: 93,
          name: "三星",
        },
        {
          value: 33,
          name: "四星",
        },
      ];
      charts.createCloudLineChart("#companyCloud", echartData);
    },
    //三色柱状图
    async initZZChart() {
      let data = {
        x: ["2020", "2021", "2022", "2023", "2024"],
        y1: [60, 95, 80, 20, 40],
        y2: [63, 60, 60, 60, 60],
        y3: [40, 90, 55, 51, 32],
      };
      charts.createZZChart("#twoCloud", data);
    },
    //智改数转图
    async createProcess() {
      charts.createProcess("#card1Chart");
    },
    //立体柱状图
    async init3DZZ() {
      charts.create3DZZ("#mesEcharts");
    },
    //地图
    async initMap(){
      charts.createMap("#map");
    }
  },
  created() {},
  mounted() {
    this.initZZ();
    this.initCloudLineChart();
    this.initZZChart();
    this.createProcess();
    this.init3DZZ();
    this.initMap();
  },
};
</script>
<style scoped lang="scss">
.map {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}
#home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("../assets/images/index/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 0.52vw;
}
.title {
  // background: url("../assets/images/index/img_top.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  width: 100vw;
  height: 7vh;
  position: absolute;
}

.echartBar {
  width: 100%;
  pointer-events: auto !important;
}
#zx1.echartBar {
  height: 14.58vh;
  margin: 1vh 0 3vh;
}
.center-main {
  position: absolute;
  width: 56vw;
  left: 22vw;
  bottom: 6vh;
  .center-card {
    display: flex;
    justify-content: space-around;
    .center-card-info {
      width: 25%;
      position: relative;
      > img {
        width: 100%;
      }
      .center-card-info-text {
        position: absolute;
        top: 3vh;
        left: 7vw;
        .center-card-info-text-sub {
          font-size: 0.52vw;
          margin-top: 0.8vh;
        }
      }
    }
  }
}
.mid {
    margin-top: 18vh;
    width: 45vw;
    height: 65vh;
}

.left {
  width: 18.25vw;
  margin-top: 8vh;
  margin-left: 20px;
  height: 92vh;
  .company-cloud {
    .company-cloud-info {
      background: url("../assets/images/index/cloud.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 3.95vh;
      width: 16.32vw;
      margin: 1.75vh auto;
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 0.625vw;
      color: #d1d6df;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    #companyCloud {
      height: 15vh;
    }
  }
  #twoCloud {
    height: 20vh;
    margin: 1vh 0;
  }
  .serve-resource {
    margin: 1vh 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .serve-resource-item {
      display: flex;
      align-items: center;
      > img {
        width: 3.438vw;
      }
    }
    .serve-resource-item2 {
      width: 45%;
      height: 14.75vh;
      background: url("../assets/images/index/percent.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      > div:first-child {
        background: url("../assets/images/index/icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 1.52vh;
        line-height: 1.52vh;
        font-size: 0.42vw;
        margin-top: 1vh;
      }
      > div:nth-child(2) {
        font-family: PangMenZhengDao;
        font-size: 1.04vw;
        color: #01bcf7;
        margin-top: 1.5vh;
        
      }
    }
  }
}
.right {
  width: 18.25vw;
  margin-top: 8vh;
  margin-right: 20px;
  height: 92vh;
  .right-card1 {
    .right-card1-middle {
      display: flex;
      justify-content: space-around;
      margin-top: 1vh;
      > div {
        width: 7.656vw;
        height: 3.625vh;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-family: PangMenZhengDao;
        font-size: 0.53vw;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        > div:first-child {
          font-size: 0.989vw;
        }
        > div {
          margin-left: 1.5vw;
        }
      }
      > div:first-child {
        background: url("../assets/images/index/img7.png");
      }
      > div:nth-child(2) {
        background: url("../assets/images/index/img8.png");
      }
    }
  }
  .right-card2 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.52vw;
    margin-top: 1vh;
    justify-content: space-between;
    .right-card2-item {
      width: 48%;
      .right-card2-item-title {
        width: 5.21vw;
        background: url("../assets/images/index/icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 2vh;
        line-height: 2vh;
        font-size: 0.52vw;
        margin: auto;
      }
      .right-card2-item-logo {
        > img {
          width: 2.71vw;
        }
      }
      .right-card2-item-num {
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 0.93vw;
        color: #ffffff;
        font-style: italic;
        position: relative;
        margin: 1vh 0;
        > div {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -1vh;
        }
      }
    }
  }
  .right-card4 {
    font-size: 0.52vw;
    .right-card4-top {
      display: flex;
      justify-content: space-around;
      margin: 1vh 0;
      > div {
        width: 48%;
        height: 5.79vh;
        display: flex;
        flex-flow: column;
        justify-content: center;
        > div:first-child {
          font-family: PangMenZhengDao;
          font-size: 0.937vw;
        }
      }
      > div:first-child {
        background: url("../assets/images/index/img_ztgsl.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        > div {
          margin-left: 1.5vw;
        }
      }
      > div:nth-child(2) {
        background: url("../assets/images/index/img_ztgsr.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        > div {
          margin-right: 1.5vw;
        }
      }
    }
    .right-card4-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 23%;
      }
      .right-card4-bottom-num {
        font-family: Myriad Pro;
        font-size: 0.78vw;
        font-weight: bold;
        color: #ffffff;
        font-style: italic;
        margin-bottom: 0.5vh;
      }
      .right-card4-bottom-name {
        font-family: Source Han Sans CN;
        font-size: 0.52vw;
        color: #c3dcf7;
        background: url("../assets/images/index/26.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 3.04vw;
        height: 1.637vh;
        margin: auto;
        img {
          width: 80%;
        }
      }
    }
  }
}
.mid-title {
  background: url("../assets/images/index/jd.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-weight: bold;
  font-size: 0.65vw;
  height: 2.2vh;
  width: 15%;
  > span {
    line-height: 2.5vh;
    margin-left: -1vw;
  }
}
.mid-legend {
  width: 15%;
  font-size: 0.55vw;
  height: 2vh;
  display: flex;
  margin-top: 1vh;
  font-weight: 400;
  .circle1,
  .circle2{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 1vh;
  }
  .circle1 {
    background: #28E3F2;
  }
  .circle2 {
    background: #FF6600;
  }
}
.mid-legend:last-child{
  height: 5vh;
}
.left-title,
.right-title {
  background: url("../assets/images/index/img_title.png");
  height: 2vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 0.75vw;
  color: #f6f9fe;
  text-align: left;
  > span {
    margin-left: 1vw;
  }
}
.mid-top {
  position: absolute;
  left: 30%;
  top: 12%;
  width: 50%;
  z-index: 999;
}
.mid-num{
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  top: 0.7vh;
  font-family: 'Microsoft YaHei';
  width: 100%;
  left: -23%;
}
.mid-num:last-child{
  left: 23%;
  color: #000;
}
.mid-area{
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  top: 0.3vh;
  width: 100%;
  font-family: 'Microsoft YaHei';
  color: #FFFFFF;
}
</style>
