<template>
  <div class="home" id="home">
    <div class="title">工业慧联产业协同-冲压产业领域</div>
    <div class="left">
      <div class="left-card">
        <div class="left-title">
          <span>企业订单总数</span>
        </div>
        <div
          style="
            width: 386px;
            position: relative;
            margin: auto;
            margin-top: 15px;
          "
        >
          <img style="width: 100%" src="@/assets/images/img1.png" alt="" />
          <div style="position: absolute; left: 100px; top: 13px">
            <div style="font-size: 14px">实施订单</div>
            <div style="font-weight: bold; font-size: 22px; color: #04eaff">
              2230
            </div>
          </div>
        </div>
        <div id="zx" class="echartBar"></div>
      </div>
      <div class="left-card">
        <div class="left-title">
          <span>企业订单平衡度</span>
        </div>
        <div id="zx2" class="echartBar"></div>
      </div>
      <div class="left-card">
        <div class="left-title">
          <span>企业外发订单(月度)</span>
        </div>
        <div class="zx3-box">
          <div id="zx3" class="echartBar"></div>
          <div class="zx3-tips">
            <div
              v-for="(item, index) in sendOrder"
              :key="index"
              class="zx3-tips-item"
            >
              <div
                class="zx3-tips-item-circle"
                :style="{ borderColor: item.color }"
              ></div>
              <div class="zx3-tips-item-name">{{ item.name }}</div>
              <div class="zx3-tips-item-value" :style="{ color: item.color }">
                {{ item.value }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-card center-card-1">
        <div class="center-title">
          <span>设备概况</span>
        </div>
        <div class="center-card-1-content">
          <div class="card-1-content-left">
            <img style="width: 339px" src="@/assets/images/device.png" alt="" />
          </div>
          <div class="card-1-content-right">
            <div style="margin-top: 40px">
              <div class="center-right-card">
                <div class="center-right-card-title">冲压设备总数</div>
                <div class="center-right-card-num" style="color: #20b1d0">
                  248
                </div>
              </div>
              <div class="center-right-card" style="margin: 18px 0">
                <div class="center-right-card-title">智能设备</div>
                <div class="center-right-card-num" style="color: #04afff">
                  198
                </div>
              </div>
              <div class="center-right-card">
                <div class="center-right-card-title">非智能设备</div>
                <div class="center-right-card-num" style="color: #a4d020">
                  50
                </div>
              </div>
            </div>
            <div class="center-right-3d">
              <div
                style="
                  font-weight: bold;
                  font-size: 14px;
                  color: #74beee;
                  margin-top: 20px;
                  margin-left: 40px;
                  text-align: left;
                "
              >
                设备国产化率
              </div>
              <div
                id="device"
                style="height: 190px; width: 290px; margin-top: 10px"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-card center-card-2">
        <div class="center-title">
          <span>企业冲压设备实时状态统计</span>
        </div>
        <div class="center-card-2-content">
          <div style="margin-top: 30px">
            <div class="card-2-item">
              <img src="@/assets/images/img6.png" alt="" />
              <div class="card-2-item-content">
                <div>201</div>
                <div>生产中</div>
              </div>
            </div>
            <div class="card-2-item">
              <img src="@/assets/images/img7.png" alt="" />
              <div class="card-2-item-content">
                <div>201</div>
                <div>计划停机</div>
              </div>
            </div>
            <div class="card-2-item">
              <img src="@/assets/images/img8.png" alt="" />
              <div class="card-2-item-content">
                <div>201</div>
                <div>待机中</div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px">
            <div class="card-2-item">
              <img src="@/assets/images/img9.png" alt="" />
              <div class="card-2-item-content">
                <div style="color: #ff3d17">38</div>
                <div>故障告警</div>
              </div>
            </div>
            <div class="card-2-item">
              <img src="@/assets/images/img10.png" alt="" />
              <div class="card-2-item-content">
                <div style="color: #ffc62c">6</div>
                <div>维修中</div>
              </div>
            </div>
            <div class="card-2-item">
              <img src="@/assets/images/img11.png" alt="" />
              <div class="card-2-item-content">
                <div>2</div>
                <div>离线中</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-card">
        <div class="right-title">
          <span>总体设备稼动率</span>
        </div>
        <div class="echartBar jdv-echart">
          <div id="JDL1"></div>
          <div id="JDL2"></div>
          <div id="JDL3"></div>
        </div>
        <div class="jdv-date">
          <div>日</div>
          <div>周</div>
          <div>月</div>
        </div>
      </div>
      <div class="right-card">
        <div class="right-title">
          <span>设备健康状态指数</span>
        </div>
        <div id="threeLineChart" class="echartBar"></div>
      </div>
      <div class="right-card right-card3">
        <div class="right-title">
          <span>企业运行时长统计</span>
        </div>
        <div class="right-card3-content">
          <div class="card3-top">
            <div>
              <div class="card-title">累计时长</div>
              <div class="card-num"><span>9.14</span><span style="font-size: 12px;">万/小时</span></div>
            </div>
            <div>
              <div class="card-title">开机率</div>
              <div class="card-num"><span>79</span><span style="font-size: 12px;">%</span></div>
            </div>
          </div>
          <div class="card3-bottom">
            <div class="card3-bottom-title">停机原因</div>
            <div id="errorChart" class="echartBar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { charts } from "../js/echart";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      sendOrder: [
        { name: "订单数量1", value: 50, color: "#72BBF5" },
        { name: "订单数量2", value: 25, color: "#CDD995" },
        { name: "订单数量3", value: 10, color: "#6DD9E5" },
        { name: "订单数量4", value: 15, color: "#9FB6CC" },
      ],
    };
  },
  methods: {
    async initZZ() {
      charts.createZX("#zx2", "orange");
      charts.createZX("#zx", "green");
    },
    async initCircle() {
      charts.createCircle("#zx3");
    },
    async init3DPie() {
      let optionData = [
        {
          name: "国内",
          value: 1503,
          scale: "63%",
          itemStyle: {
            opacity: 0.2,
            color: "#00A8FF",
          },
        },
        {
          name: "非国内",
          value: 803,
          scale: "32%",
          itemStyle: {
            opacity: 0.2,
            color: "#00D7E9",
          },
        },
      ];
      charts.create3dPie("#device", optionData, "总数");
    },
    async init3DPie2() {
      let optionData = [
        {
          name: "计划维修",
          value: 15,
          scale: "30%",
          itemStyle: {
            opacity: 0.2,
            color: "rgb(0,215,233)",
          },
        },
        {
          name: "日常待机",
          value: 16,
          scale: "32%",
          itemStyle: {
            opacity: 0.2,
            color: "#84F1C4",
          },
        },{
          name: "故障",
          value: 16,
          scale: "28%",
          itemStyle: {
            opacity: 0.2,
            color: "#4EC5FF",
          },
        }
      ];
      charts.create3dPie("#errorChart", optionData, "总数");
    },
    /**获取三折线图表 */
    async initThreeLineChart() {
      let list = {
        x: ["11/03", "11/04", "11/05", "11/06", "11/07"],
        y1: [100, 110, 95, 102, 92],
        y2: [48, 75, 50, 60, 55],
        y3: [4, 28, 58, 26, 10],
      };
      charts.createThreeLineChart("#threeLineChart", list);
    },
    /**获取稼动率 */
    async initJDLChart() {
      let data = [
        { name: "日", value: 90, color: "#04EAFF" },
        { name: "周", value: 50, color: "#4BCF12" },
        { name: "月", value: 30, color: "#FFFC00" },
      ];
      charts.createCircleBar("#JDL1", data[0]);
      charts.createCircleBar("#JDL2", data[1]);
      charts.createCircleBar("#JDL3", data[2]);
    },
  },
  created() {},
  mounted() {
    this.initZZ();
    this.initThreeLineChart();
    this.initJDLChart();
    this.initCircle();
    this.init3DPie();
    this.init3DPie2();
  },
};
</script>
<style scoped lang="scss">
#home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.title {
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 36px;
  color: #97e5ff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1%;
}
.center {
  width: 912px;
  font-family: "SourceHanSansCN";
  margin-top: 117px;
  .center-card-1 {
    background: url("../assets/images/center1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 632px;
    margin-bottom: 24px;
    .center-card-1-content {
      display: flex;
      margin-top: 10px;
      height: 575px;
      align-items: center;
      padding-left: 80px;
      .card-1-content-right {
        height: 100%;
        margin-left: 160px;
        .center-right-card {
          background: url("../assets/images/img2.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 78px;
          width: 248px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          .center-right-card-title {
            font-size: 15px;
            color: #ffffff;
            text-shadow: 0px 3px 2px rgba(0, 14, 17, 0.19);
            width: 150px;
          }
          .center-right-card-num {
            font-family: Myriad Pro;
            font-weight: bold;
            font-size: 23px;
            width: 150px;
            transform: skewX(-15deg);
          }
        }
        #device {
          background: url("../assets/images/3dPie-plate.png") no-repeat center;
          background-size: 70%;
          background-position: center 60px;
        }
      }
    }
  }
  .center-card-2 {
    background: url("../assets/images/center2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 304px;
    .center-card-2-content {
      height: 260px;
      padding: 0 80px;
      > div {
        display: flex;
        justify-content: space-between;
        .card-2-item {
          display: flex;
          align-items: center;
          img {
            width: 84px;
          }
          .card-2-item-content {
            margin-left: 10px;
            width: 70px;
            > div:first-child {
              font-size: 26px;
              color: #ddebfa;
              font-weight: 800;
            }
            > div:nth-child(2) {
              font-size: 14px;
              color: #ddebfa;
            }
          }
        }
      }
    }
  }
}
.echartBar {
  width: 100%;
  pointer-events: auto !important;
}
#zx.echartBar {
  height: 200px;
  margin-top: -20px;
}
#zx2.echartBar,
#threeLineChart.echartBar {
  height: calc(100% - 45px);
}
.zx3-box {
  display: flex;
  height: calc(100% - 45px);
  > div {
    width: 50%;
  }
  .zx3-tips {
    margin: auto;
    width: 35%;
    .zx3-tips-item {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #c9cdd6;
      padding: 10px 0;
      .zx3-tips-item-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid;
        margin-right: 10px;
      }
      .zx3-tips-item-name {
        font-size: 12px;
        margin-right: 20px;
      }
      .zx3-tips-item-value {
        font-size: 18px;
        font-family: "PangMenZhengDao";
      }
    }
  }
}
#zx3.echartBar {
  height: 100%;
}

.left {
  width: 452px;
  margin-top: 117px;
  margin-left: 20px;
  .left-card {
    background: url("../assets/images/left1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 305px;
    margin-bottom: 25px;
  }
}
.right {
  width: 476px;
  margin-top: 117px;
  margin-right: 20px;
  .right-card {
    background: url("../assets/images/left1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 305px;
    margin-bottom: 25px;
    .jdv-echart {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      > div {
        width: 30%;
        height: 150px;
      }
    }
    .jdv-date {
      display: flex;
      justify-content: space-around;
      > div {
        width: 30%;
      }
    }
  }
  .right-card3 {
    .right-card3-content {
      padding: 0 20px;
      .card3-top {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        >div {
          background: url("../assets/images/img12.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 72px;
          width: 202px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          .card-title {
            font-size: 14px;
            color: #ffffff;
            text-shadow: 0px 3px 2px rgba(0, 14, 17, 0.19);
            width: 130px;
          }
          .card-num {
            font-family: Myriad Pro;
            font-weight: bold;
            font-size: 18px;
            width: 130px;
            color: #20B1D0;
          }
        }
        >div:nth-child(2){
          background: url("../assets/images/img13.png") no-repeat 0 0 100% 100%;
        }
      }
      .card3-bottom{
        margin-top: 20px;
        .card3-bottom-title{
          font-weight: bold;
          font-size: 14px;
          color: #74BEEE;
          text-align: left;
        }
        #errorChart{
          height: 135px;
        }
      }
    }
  }
}

.left-title,
.right-title,
.center-title {
  font-size: 16px;
  font-family: "SourceHanSansCN";
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 9px 35px;
}
</style>
