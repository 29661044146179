import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Big2 from '../views/Big2.vue'
import Big3 from '../views/Big3.vue'
import Big4 from '../views/Big4.vue'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/index',
    name: 'Index',
    component: Index
  },{
    path: '/big2',
    name: 'Big2',
    component: Big2
  },{
    path: '/big3',
    name: 'Big3',
    component: Big3
  },{
    path: '/big4',
    name: 'Big4',
    component: Big4
  }
]

const router = new VueRouter({
  routes
})

export default router
